
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RVSScoreService from '../rvs-score.service';

@Component({
    components: {
        CustomSelect,
    },
})
export default class RVSHeader extends Vue {
    @inject(UserServiceS)
    private userService!: UserService;

    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @inject(KEY.RVSScoreService)
    private rvsScoreService!: RVSScoreService;

    get provider() {
        return this.rvsScoreService.settings.provider;
    }

    set provider(value: string | null) {
        this.rvsScoreService.settings.provider = value;
    }

    get providers() {
        return [
            {
                name: this.$tc('All Channels'),
                value: null,
            },
            ...this.providersService.toItemsList(this.providersService.ratesProviders),
        ];
    }

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId = +this.$route.params.hotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.getHotelName(currentHotelId);
    }
}

