
import { Component, Vue } from 'vue-property-decorator';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import HomeHotelTitle from '@/modules/home/components/home-hotel-title.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';

import RVSPageTabs from '../components/rvs-page-tabs.vue';
import RVSHeader from '../components/rvs-header.vue';
import OverviewTab from '../tabs/overview.tab.vue';
import DetailsTab from '../tabs/details.tab.vue';

type TabComponent = typeof Vue & { key: string };

@Component({
    components: {
        DateDocumentFilter,
        HomeHotelTitle,
        PageWrapper,
        RVSHeader,
        RVSPageTabs,
    },
})
export default class RVSIndexPage extends Vue {
    tabComponents: TabComponent[] = [
        OverviewTab,
        DetailsTab,
    ];

    get tabComponent() {
        const tab = this.$route.params.tab || this.tabComponents[0].key;
        const name = this.$route.name || '';

        if (!name.endsWith('.tab')) {
            this.$router.replace({
                name: `${name}.tab`,
                params: {
                    ...this.$route.params,
                    tab,
                },
            });
        }

        return this.tabComponents.find(component => component.key === tab);
    }
}
