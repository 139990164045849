
import { Component, Vue } from 'vue-property-decorator';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import RVSScoreContainer from '../components/rvs-score.vue';
import RVSRootCause from '../components/rvs-root-cause.vue';
import RVSRootCauseAnalysis from '../components/rvs-root-cause-analysis.vue';
import RVSOvertimeGraph from '../components/rvs-overtime.vue';
import RVSCalendar from '../components/rvs-calendar.vue';

@Component({
    components: {
        RVSScoreContainer,
        RVSRootCause,
        RVSRootCauseAnalysis,
        RVSOvertimeGraph,
        RVSCalendar,
        CustomSelect,
    },
})
export default class OverviewTab extends Vue {
    static key = 'overview';
}
