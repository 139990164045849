
import { Vue, Component, Prop } from 'vue-property-decorator';

type TabComponent = typeof Vue & { key: string };

@Component({})
export default class RVSPageTabs extends Vue {
    @Prop({
        type: Array,
        required: true,
    })
    tabs!: TabComponent[];

    get currentTab() {
        return this.$route.params.tab || this.tabs[0].key;
    }

    getRouteFor(tabKey: string) {
        return {
            name: 'hotel.rvs.tab',
            params: {
                ...this.$route.params,
                tab: tabKey,
            },
        };
    }
}
